import "./css/styles.css";
import { Home } from "./pages/home.js";
import { NoPage } from "./pages/404";
import { Profile } from "./pages/profile";
import {Example} from "./pages/example";
import Disclaimer from "./pages/disclaimer.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

function App() {
  return (
    
    <div styles={pageStyles}>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route index element={<Home />} />
            <Route path="/example" element={ <Example /> }/>
            <Route path="/disclaimer" element={<Disclaimer />}/>
            <Route path="/profile/:id" element={<Profile />}/>
            <Route path="*" element={<NoPage  />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
