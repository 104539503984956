import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Layout from '../components/layout.js';
import ReactPaginate from 'react-paginate';
import MediaQuery from 'react-responsive';

import '../css/styles.css';
import '../css/paginate.css';

export class Example extends Component {
    constructor() {
        super();
    
        this.state = {
          offset: 0,
          perPage: 10,
          currentPage: 0,
          loading: true
        }
    
        this.handlePageClick = this.handlePageClick.bind(this);
      }
    
      receivedData() {
        fetch('http://54.147.237.188/api/arrests', {
            method: 'GET',
            mode: 'cors',
            'Access-Control-Allow-Origin': '*'
        })           
        .then(response => response.json())
        .then(data => {
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(item => 
                <div>
                    <Layout>
                        
                        <div>
                            {/* Mobile Version */}
                            <MediaQuery maxWidth={600}>
                            <div class="card" style={{width: "auto"}}>
                            <img class="card-img-top" src={item['ArrestImage']} style={{width: "100%"}} alt="mugshots"/>
                                <div class="card-body">
                                <h5 class="card-title" style={{textAlign: "center"}}>{item['FullName']}</h5>
                                    <ul>
                                        <li>{JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Date Arrested'] ? JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Date Arrested'] : 'NA'}</li>
                                    </ul>
                                    <Link to={`/profile/${item['BookingNumber']}`} ><button class="btn btn-primary">View full report</button></Link>
                                </div>
                            </div>
                            </MediaQuery>

                            {/* Desktop Version */}
                            <MediaQuery minWidth={601}>
                                <div className="container">
                                <div className="row">
                                        <div className="col-md-6">
                                        <div className="card" style={{width: "45%"}}>
                                            <img src={item['ArrestImage']} style={{width:"100%"}} alt="mugshots"/>
                                            <div className="container">
                                            <h5 style={{fontSize:"80%"}} ><b>{item['FullName']}</b></h5> 
                                            <p>Date of arrest: {JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Date Arrested'] ? JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Date Arrested'] : 'NA'}</p> 
                                            </div>
                                        </div> 
                                        </div>

                                    <div className="col-md-6">
                                        <div className="h-100 p-5 bg-light border rounded-3">
                                        <h2>Arrest Information</h2>
                                        <ul>
                                            <li>Arrest City - {JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Arrest City'] ? JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Arrest City'] : 'N/A'}</li>
                                            <li>Arresting Agency -  {JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Arresting Agency'] ? JSON.parse(item['ArrestInfo'].replace(/'/g, '"'))['Arresting Agency'] : 'N/A'}</li>
                                            <li>Charge Description - {JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Charge Description'] ? JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Charge Description'] : 'N/A'}</li>
                                            <li>Bond Amount - {JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Bond Amount'] ? JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Bond Amount'] : 'N/A' }</li>
                                            <li>Incident Number - {JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Incident Number'] ? JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Incident Number'] : 'N/A'}</li>
                                            <li>Booking number - {item['BookingNumber']}</li>
                                            <li>Release Date - {JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Release Date'] ? JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Release Date']: 'N/A'}</li>
                                            <li>Arraign Date - {JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Arraign Date'] ? JSON.parse(item['CriminalChargesInfo'].replace(/'/g, '"'))['Arraign Date']: 'N/A'}</li>
                                        </ul>
                                        <p style={{fontSize: "9px"}}>DISCLAIMER: ALL ARE PRESUMED INNOCENT UNTIL PROVEN GUILTY IN A COURT OF LAW. THE INDIVIDUALS DEPICTED HAVE BEEN ARRESTED/RECOMMITED BUT NOT CONVICTED AT THE TIME OF THIS POSTING. EVERY EFFORT IS MADE TO ENSURE THE ACCURACY OF INFORMATION POSTED ON THIS WEBSITE. HOWEVER, WE DO NOT GUARANTEE THE ACCURACY OR TIMELINESS OF THE CONTENT OF THIS WEBSITE. NAMES MAY BE SIMILAR OR IDENTICAL TO OTHER INDIVIDUALS. FOR LATEST CASE STATUS, CONTACT THE OFFICIAL LAW ENFORCEMENT AGENCY WHICH ORIGINALLY RELEASED THE DATA. WE ARE IN NO WAY AFFILIATED WITH ANY LAW ENFORCEMENT AGENCIES.</p>
                                        <button className="btn btn-outline-secondary" type="button"><Link to={`/profile/${item['BookingNumber']}`} >View full report</Link></button>
                                        </div>
                                    </div>
                                </div>
                                    <hr style={{height:"1px", border:"none", color:"#333",backgroundColor: "#333"}}/>
                                </div>
                                </MediaQuery>
                        </div>
                    </Layout>
                </div>
            )
            this.setState({
                pageCount: Math.ceil(data.length / this.state.perPage), postData
            })
            this.setState({loading: false})

            });
        }
    
      handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
    
        this.setState({
            currentPage: selectedPage,
            offset: offset,
        }, () => {
            this.receivedData()
        });
    
      };
    
      componentDidMount() {
        this.receivedData()
      }
    render() {
        
        return (
            <div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {this.state.postData}
                <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                </div>
            </div>

        )
    }
}
