import React from 'react'
import Layout from '../components/layout.js';

// styles
const pageStyles = {
    color: "#232129",
    padding: 96,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

export default function Disclaimer() {
    return (
        <main style={pageStyles}>
        <Layout>
            <div>
                <h2>Disclaimer</h2>

                <p>DISCLAIMER: ALL ARE PRESUMED INNOCENT UNTIL PROVEN GUILTY IN A COURT OF LAW. THE INDIVIDUALS DEPICTED HAVE BEEN ARRESTED/RECOMMITED BUT NOT CONVICTED AT THE TIME OF THIS POSTING. EVERY EFFORT IS MADE TO ENSURE THE ACCURACY OF INFORMATION POSTED ON THIS WEBSITE. HOWEVER, WE DO NOT GUARANTEE THE ACCURACY OR TIMELINESS OF THE CONTENT OF THIS WEBSITE. NAMES MAY BE SIMILAR OR IDENTICAL TO OTHER INDIVIDUALS. FOR LATEST CASE STATUS, CONTACT THE OFFICIAL LAW ENFORCEMENT AGENCY WHICH ORIGINALLY RELEASED THE DATA. WE ARE IN NO WAY AFFILIATED WITH ANY LAW ENFORCEMENT AGENCIES.</p>

                <strong>THIS SITE MAY USE COOKIES TO IMPROVE YOUR WEB EXPERIENCE. IF YOU USE OUR WEB APPLICATION, YOU AGREE WITH OUR TERMS AND AGREEMENTS WHEN IT COMES TO USING OUR WEBSITE AND WE VOW TO NEVER USE OR SELL YOUR PERSONAL INFO TO ANYONE.</strong>
                
            </div>
        </Layout>
        </main>
    )
}