import React from 'react';
import '../css/styles.css';

export default function Navbar() {

    return (
        <div>
            <head>
                <meta charset="UTF-8" />
                <meta name="description" content="SRQ Mugshots - Arrest Records " />

                <meta name="keywords" content="sarasota mugshots, mugshots sarasota, sarasota florida mugshots, srq mugshots, mugshots srq, sarasota county mugshots, venice florida mugshots, venice fl mugshots, sarasota fl mugshots, brandenton mugshots, bradenton fl mugshots, brandenton florida mugshots, florida brandenton mugshots, florida sarasota mugshots, florida venice mugshots, sarasota mugshots records, sarasota mugshot records, sarasota arrests, venice arrests, bradenton arrests, sarasota county arrests, sarasota fl arrests " />
        
                <meta name="description" content="Sarasota County Mugshots Records" />

                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous"/>
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossorigin="anonymous"></script>
                <meta name="viewport" content="width=device-width, initial-scale=1" />

            </head>

            <body>

                <nav className="navbar navbar-default navbar-fixed-top">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" data-toggle="collapse"
                                    data-target="#myNavbar">
                                <span className="glyphicon glyphicon-menu-hamburger"></span>
                            </button>
                        <div className="navbar-left logo"> 
                            <style type="text/css">
                                {/* .st0{fill:#000;} */}
                            </style>
                                <polygon className="st0" points="82.1,12 7.4,138.7 38.7,237.3 "/>
                                <polygon className="st0" points="92.7,5.3 199.4,8.7 223.4,82.7 "/>
                                <polygon className="st0" points="89.4,8.7 223.4,86.7 42.1,237.3 "/>
                                <polygon className="st0" points="206.1,15.3 262.7,66 228.1,82.7 "/>
                                <polygon className="st0" points="262.7,70 228.1,86 266.4,175.3 "/>
                                <polygon className="st0" points="228.1,92.7 262.7,179.3 173.4,328 "/>
                                <polygon className="st0" points="223.4,92.7 44.7,240 167.7,336 "/>
                                <polygon className="st0" points="252.1,210.3 184.4,380.3 170.7,339.7 "/>
                                <polygon className="st0" points="60.1,260.3 167.4,341 180.4,380.3 "/>
                        </div>
                        <h1 className="brand brand-name navbar-left">SRQ Mugshots</h1>
                        </div>
                        <div className="collapse navbar-collapse navbar-right" id="myNavbar">
                            <ul className="nav navbar-nav">
                                <li><a href="/">Home</a></li>
                                {/* <li><a href="/county">County</a></li>
                                <li><a href="/gender">Gender</a></li> */}
                                <li><a href="/disclaimer">Disclaimer</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </body>
        </div>
    )
}
