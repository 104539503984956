import React, { Component } from 'react';
import Layout from '../components/layout.js';
import { NoPage } from './404';

import axios from 'axios';

import '../css/styles.css';
import '../css/profile.css';
export class Profile extends Component  {
    constructor(props) {
      super(props)

      this.state = {
        id: "",
        exists: false,
        bookingUrl: ""
      }

    }

    receivedData(bookingId) {
      const url = 'http://54.147.237.188/api/arrests'
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        };
        
        axios.get(url, {headers}).then(res =>{
          console.log(res.data[0])
          for (let index = 0; index < res.data.length; index++) {
            if (String(res.data[index]['BookingNumber']) === bookingId) {
              const postData = res.data[index]
              console.log(postData)
              this.setState({ exists: true, postData }, () => {
                console.log(this.state.postData, 'exists');
              }); 
            }
          }
        })
        .catch(error => {
          console.log(`Error: ${error}`)
        });
        
    }

    componentDidMount() {
      const bookingId = window.location.pathname.replace('/profile/', '')
      const url = "https://cms.revize.com/revize/apps/sarasota/booking.php?bkg=" + bookingId
      this.receivedData(bookingId)
      this.setState({id: bookingId})
      this.setState({bookingUrl: url})
    }

    render() {
        if (this.state.exists) {
          return (
              <div>
                <Layout>
                <div className="container">
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="row">
                <br/>
                <br/>
                <br/>
                <br/>
                  {
                    <div>
                      <div className="col-lg-5 col-md-6">
                        <div className="mb-2">
                          <img className="w-100" src={this.state.postData[2]} alt="arrestimage" />
                          <div className="mb-2 d-flex">
                            <h4 className="font-weight-normal">{this.state.postData[1]}</h4>
                            <div className="social d-flex ml-auto">
                                <p className="pr-2 font-weight-normal"><a href={this.state.bookingUrl} target="_blank">View Sarasota Sherrif report</a></p>
                            </div>
                            <div className="mb-2">
                              <ul className="list-unstyled">
                                  <li className="media">
                                  <span className="w-25 text-black font-weight-normal">Arrest City:</span>
                                  <label className="media-body">{JSON.parse(this.state.postData[5].replace(/'/g, '"'))['Arrest City'] ? JSON.parse(this.state.postData[5].replace(/'/g, '"'))['Arrest City'] : 'N/A'}</label>
                                  </li>
                                  <li className="media">
                                  <span className="w-25 text-black font-weight-normal">Agency: </span>
                                  <label className="media-body">{JSON.parse(this.state.postData[5].replace(/'/g, '"'))['Arresting Agency'] ? JSON.parse(this.state.postData[5].replace(/'/g, '"'))['Arresting Agency'] : 'NA'}</label>
                                  </li>
                                  <li className="media">
                                  <span className="w-25 text-black font-weight-normal">Booking #: </span>
                                  <label className="media-body">{this.state.postData[3]}</label>
                                  </li>
                                  <li className="media">
                                  <span className="w-25 text-black font-weight-normal">Bond Amount: </span>
                                  <label className="media-body">{JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Bond Amount'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Bond Amount'] : 'NA'}</label>
                                  </li>
                                  <li className="media">
                                  <span className="w-25 text-black font-weight-normal">Incident #: </span>
                                  <label className="media-body">{JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Incident Number'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Incident Number'] : 'NA'}</label>
                                  </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-6 pl-xl-3">
                        <h5 className="font-weight-normal"><strong>Charge Descriptions</strong></h5>
                          <ul>
                              <li>Date Arrested: {JSON.parse(this.state.postData[5].replace(/'/g, '"'))['Date Arrested'] ? JSON.parse(this.state.postData[5].replace(/'/g, '"'))['Date Arrested'] : 'NA'}</li>
                              <li>Charge Description: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Charge Description'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Charge Description'] : 'NA'}</li>
                              <li>Counts: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Counts'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Counts'] : 'NA'}</li>
                              <li>Release Date: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Release Date'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Release Date'] : 'NA'}</li>
                              <li>Arraign Date: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Arraign Date'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Arraign Date'] : 'NA'}</li>
                              <li>Bond Agency: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Bond Agency'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Bond Agency'] : 'NA'}</li>
                              <li>Docket Number: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Docket Number'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Docket Number'] : 'NA'}</li>
                              <li>Hold For: {JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Hold For'] ? JSON.parse(this.state.postData[7].replace(/'/g, '"'))['Hold For'] : 'NA'}</li>
                          </ul>
                        
                        
                        <h5 className="font-weight-normal"><strong>Confinement Information</strong></h5>
                          <ul>
                              <li>Released Reason: {JSON.parse(this.state.postData[6].replace(/'/g, '"'))['Releasing Reason'] ? JSON.parse(this.state.postData[6].replace(/'/g, '"'))['Releasing Reason'] : 'NA'}</li>
                          </ul>
                        
                        <h5 className="font-weight-normal">Personal Info</h5>
                          <ul>
                              <li>Age at Arrest: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Age at Arrest'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Age at Arrest'] : 'NA'}</li>
                              <li>Race: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Race'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Race'] : 'NA'}</li>
                              <li>Sex: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Sex'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Sex'] : 'NA'}</li>
                              <li>Height: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Height'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Height'] : 'NA'}</li>
                              <li>Weight: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Weight'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Weight'] : 'NA'}</li>
                              <li>Eye Color: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Eye Color'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Eye Color'] : 'NA'}</li>
                              <li>Place of Birth: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Place of Birth'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Place of Birth'] : 'NA'}</li>
                              <li>Marriage Status: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Marital Status'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Marital Status'] : 'NA'}</li>
                              <li>Citizen: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Citizen'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Citizen'] : 'NA'}</li>
                              <li>Occupation: {JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Occupation'] ? JSON.parse(this.state.postData[4].replace(/'/g, '"'))['Occupation'] : 'NA'}</li>
                          </ul>
                      </div>
                    </div>
                } 

                </div>
                </div>
                </Layout>
              </div>
          )
        }
        return (
          <NoPage />
        )
    }
}
