import React from 'react';
import Navbar from './navbar.js';

// Layout for entire application.
export default function Layout({ children }) {
    return (
        <div className="layout">
            <title>SRQ Mugshots</title>
            {/* Navigation bar for every page */}
            <Navbar />

            {/* My different url pages */}
            <div className="content">
                { children }
            </div>

            

            {/* Footer */}
            <div className="footer">
                
            </div>
        </div>
    )
}
